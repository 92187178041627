<template>
  <!-- <div class="exam-display">
    <h2 class="table-number">Tavolo {{ studente.tavolo }}</h2>
    <p class="table-info">{{ studente.Name }}</p>
    <p class="table-info" style="font-style: italic;">"{{ piatto }}"</p>
    <div class="tables-container">
      <div class="table-card">
        <h2 class="table-number">Tavolo {{ studente.tavolo }}</h2>
        <p class="table-info">{{ studente.Name }}</p>
        <p class="table-info" style="font-style: italic;">"{{ piatto }}"</p>
      </div>
    </div>
  </div> -->

  <div class="exam-display">
    <div>
      <h2 class="table-number">Tavolo {{ tavolo }}</h2>
    </div>
    <div>
      <p class="dish">"{{ piatto.NomePiatto }}"</p>
    </div>
    <hr class="separator">
    <div class="container-anagrafica">
      <div v-if="immagine" class="immagine">
        <img :src="immagine" style="max-width: 200px;border-radius: 2vw;">
      </div>
      <div class="anagrafica">
        <div v-for="stud in studente" v-bind:key="stud">
          <p class="table-info">{{ stud }}</p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Piatto',
  props: {
    tavolo: String,
    piatti: Array,
    immagine: String
  },
  data: function(){
    return {
      // piatto: {},
      // studente: {}
    }
  },
  computed:{
    piatto(){
      let piatto;
      for(let p of this.piatti){
        if(p.Tavolo == this.tavolo){
            piatto = p;
            break;
        }
      }
      return piatto
    },
    studente(){
    console.log('test')
      let studente = {};
      for(let p of this.piatti){
        if(p.Tavolo == this.tavolo){
            studente['Nome'] = p.NomeCandidato;
            studente['Eta'] = p.Eta + ' anni';
            studente['Citta'] = p.Citta;
            break;
        }
      }
      return studente
    }
  },
  mounted: function() {
    // console.log('MOUNTED', this.piatti)
    // for(let p of this.piatti){
    //   console.log(p);
    //   if(p.tavolo == this.tavolo){
    //       console.log('piatto:',p, 'tavolo', this.tavolo)
    //       this.piatto = p;
    //       this.studente['Nome'] = p.NomeCandidato;
    //       this.studente['Eta'] = p.Eta;
    //       this.studente['Citta'] = p.Citta;
    //       break;
    //   }
    // }
  }
};
</script>

<style scoped>
.separator {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc; /* Linea sottile grigia */
  margin: 10px 0;

}
.container-anagrafica {
  display: flex; 
  gap: 10%;
}
.anagrafica{
  display: flex; 
  flex-direction: column; 
  grid-template-columns: 2fr
}
.immagine {
  margin-top: auto
}
.col {
  flex: 1 0 0;
}
.exam-display {
/* display: flex;
flex-direction: column;
align-items: center;
justify-content: center; */
font-family: Arial, sans-serif;
padding: 2vw;
background: #f8f9fa;
border: 0.2vw solid #3b5674;
border-radius: 2vw;
}

.table-number {
font-size: 3vw;
color: #3b5674;
margin-bottom: 1vw;
text-align: right;
}

.dish {
  text-align: center;
  font-style: italic;
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 5vw;
}

.table-info {
  text-align: left;
  font-size: 3vw;
  /* margin-bottom: 1vw; */
  font-weight: bold;
}

.title-info {
font-size: 1vw;
}

@media screen and (orientation: landscape) {
  .table-number{
    font-size: 2vw;
    margin-bottom: 0.7vw;
  }
  .table-info {
    font-size: 2vw;
  }
  .title-info {
    font-size: 0.7vw;
  }
  .dish{
    font-size: 2vw;
    margin-bottom: 0vw;
  }
}

/* Media query per schermi con larghezza inferiore a 768px */
@media (max-width: 768px) {
.table-number {
  font-size: 3.5vw;
}
.table-info {
  font-size: 3vw;
}
}

/* Media query per schermi con larghezza inferiore a 480px */
@media (max-width: 480px) {
.table-number {
  font-size: 4vw;
}
.table-info {
  font-size: 3.5vw;
}
}

</style>
